<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <a-card>
          <template slot="title">
            <strong v-if="action === 'create'">Form Template Korporasi</strong>
            <strong v-if="action === 'update'"
              >Update Template Korporasi</strong
            >
            <a-button
              size="default"
              type="danger"
              class="ml-2"
              @click="resetForm"
              >Back</a-button
            >
          </template>
          <div class="content">
            <div class="row pl-3 pr-3 pt-1 pb-1">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div class="row">
                  <div
                    class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-1"
                    style="padding-right: unset"
                  >
                    <label class="mt-1">Periode</label>
                  </div>
                  <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                    <a-date-picker
                      style="width: 130px"
                      v-model="formData.periode"
                      :allowClear="false"
                      format="DD-MM-YYYY"
                      @change="
                        (dateMoment, dateString) =>
                          changeDateInput(dateMoment, dateString, [
                            'formData',
                            'tgl',
                          ])
                      "
                    />
                  </div>
                  <div
                    class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-2"
                    style="padding-right: unset"
                  >
                    <label class="mt-1">Kode</label>
                  </div>
                  <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                    <a-input
                      :readOnly="false"
                      class=""
                      style="width: 400px"
                      placeholder="Kode"
                      v-model="formData.kode"
                    ></a-input>
                  </div>
                  <div
                    class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-2"
                    style="padding-right: unset"
                  >
                    <label class="mt-1">CIF</label>
                  </div>
                  <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                    <a-input
                      :readOnly="false"
                      class=""
                      style="width: 400px"
                      placeholder="CIF"
                      v-model="formData.cif"
                    ></a-input>
                  </div>
                  <div
                    class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-2"
                    style="padding-right: unset"
                  >
                    <label class="mt-1">Rekening</label>
                  </div>
                  <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                    <a-input
                      :readOnly="false"
                      class=""
                      style="width: 400px"
                      placeholder="Rekening"
                      v-model="formData.rekening"
                    ></a-input>
                  </div>
                  <div
                    class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-2"
                    style="padding-right: unset"
                  >
                    <label class="mt-1">Jenis Produk</label>
                  </div>
                  <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                    <a-input
                      :readOnly="false"
                      class=""
                      style="width: 400px"
                      placeholder="Jenis Produk"
                      v-model="formData.jenis_produk"
                    ></a-input>
                  </div>
                  <div
                    class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-2"
                    style="padding-right: unset"
                  >
                    <label class="mt-1">Nominal</label>
                  </div>
                  <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                    <a-input
                      :readOnly="false"
                      class=""
                      style="width: 400px"
                      placeholder="Nominal"
                      v-model="formData.nominal"
                    ></a-input>
                  </div>
                  <div
                    class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-2"
                    style="padding-right: unset"
                  >
                    <label class="mt-1">Nama</label>
                  </div>
                  <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                    <a-input
                      :readOnly="false"
                      class=""
                      style="width: 400px"
                      placeholder="Nama"
                      v-model="formData.nama"
                    ></a-input>
                  </div>
                  <div
                    class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-2"
                    style="padding-right: unset"
                  >
                    <label class="mt-1">NIB SKU SKDU</label>
                  </div>
                  <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                    <a-input
                      :readOnly="false"
                      class=""
                      style="width: 400px"
                      placeholder="NIB SKU SKDU"
                      v-model="formData.nib_sku_skdu"
                    ></a-input>
                  </div>
                  <div
                    class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-2"
                    style="padding-right: unset"
                  >
                    <label class="mt-1">Masa Berlaku</label>
                  </div>
                  <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                    <a-input
                      :readOnly="false"
                      class=""
                      style="width: 400px"
                      placeholder="Masa Berlaku"
                      v-model="formData.masa_berlaku"
                    ></a-input>
                  </div>
                  <div
                    class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-2"
                    style="padding-right: unset"
                  >
                    <label class="mt-1">NPWP</label>
                  </div>
                  <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                    <a-input
                      :readOnly="false"
                      class=""
                      style="width: 400px"
                      placeholder="NPWP"
                      v-model="formData.npwp"
                    ></a-input>
                  </div>
                  <div
                    class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-2"
                    style="padding-right: unset"
                  >
                    <label class="mt-1">Bidang Usaha</label>
                  </div>
                  <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                    <a-input
                      :readOnly="false"
                      class=""
                      style="width: 400px"
                      placeholder="Bidang Usaha"
                      v-model="formData.bidang_usaha"
                    ></a-input>
                  </div>
                  <div
                    class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-2"
                    style="padding-right: unset"
                  >
                    <label class="mt-1">Tempat Pendirian</label>
                  </div>
                  <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                    <a-input
                      :readOnly="false"
                      class=""
                      style="width: 400px"
                      placeholder="Tempat Pendirian"
                      v-model="formData.tempat_pendirian"
                    ></a-input>
                  </div>
                  <div
                    class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-2"
                    style="padding-right: unset"
                  >
                    <label class="mt-1">Tgl Pendirian</label>
                  </div>
                  <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                    <a-input
                      :readOnly="false"
                      class=""
                      style="width: 400px"
                      placeholder="Tgl Pendirian"
                      v-model="formData.tgl_pendirian"
                    ></a-input>
                  </div>
                  <div
                    class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-2"
                    style="padding-right: unset"
                  >
                    <label class="mt-1">Bentuk Korporasi</label>
                  </div>
                  <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                    <a-input
                      :readOnly="false"
                      class=""
                      style="width: 400px"
                      placeholder="Bentuk Korporasi"
                      v-model="formData.bentuk_korporasi"
                    ></a-input>
                  </div>
                  <div
                    class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-2"
                    style="padding-right: unset"
                  >
                    <label class="mt-1">Alamat</label>
                  </div>
                  <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                    <a-input
                      :readOnly="false"
                      class=""
                      style="width: 400px"
                      placeholder="Alamat"
                      v-model="formData.alamat"
                    ></a-input>
                  </div>
                  <div
                    class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-2"
                    style="padding-right: unset"
                  >
                    <label class="mt-1">Provinsi</label>
                  </div>
                  <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                    <a-input
                      :readOnly="false"
                      class=""
                      style="width: 400px"
                      placeholder="Provinsi"
                      v-model="formData.provinsi"
                    ></a-input>
                  </div>
                  <div
                    class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-2"
                    style="padding-right: unset"
                  >
                    <label class="mt-1">Kota</label>
                  </div>
                  <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                    <a-input
                      :readOnly="false"
                      class=""
                      style="width: 400px"
                      placeholder="Kota"
                      v-model="formData.kota"
                    ></a-input>
                  </div>
                  <div
                    class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-2"
                    style="padding-right: unset"
                  >
                    <label class="mt-1">Telepon</label>
                  </div>
                  <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                    <a-input
                      :readOnly="false"
                      class=""
                      style="width: 400px"
                      placeholder="Telepon"
                      v-model="formData.telepon"
                    ></a-input>
                  </div>
                  <div
                    class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-2"
                    style="padding-right: unset"
                  >
                    <label class="mt-1">Sumber Dana</label>
                  </div>
                  <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                    <a-input
                      :readOnly="false"
                      class=""
                      style="width: 400px"
                      placeholder="Sumber Dana"
                      v-model="formData.sumberdana"
                    ></a-input>
                  </div>
                  <div
                    class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-2"
                    style="padding-right: unset"
                  >
                    <label class="mt-1">Pendapatan Per Tahun</label>
                  </div>
                  <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                    <a-input
                      :readOnly="false"
                      class=""
                      style="width: 400px"
                      placeholder="Pendapatan Per Tahun"
                      v-model="formData.pendapatan_per_tahun"
                    ></a-input>
                  </div>
                  <div
                    class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-2"
                    style="padding-right: unset"
                  >
                    <label class="mt-1">Tujuan Pembukaan</label>
                  </div>
                  <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                    <a-input
                      :readOnly="false"
                      class=""
                      style="width: 400px"
                      placeholder="Tujuan Pembukaan"
                      v-model="formData.tujuan_pembukaan"
                    ></a-input>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-4">
                    <a-button
                      size="default"
                      :class="
                        action === 'update'
                          ? 'btn btn-warning'
                          : 'btn btn-primary'
                      "
                      @click="handleSubmit"
                      >{{
                        action === "update" ? "Save Changes" : "Submit"
                      }}</a-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </a-card>
      </div>
    </div>
  </div>
</template>

<script>
// import debounce from 'lodash/debounce'
// import { Modal } from 'ant-design-vue'
import moment from 'moment'
import * as lou from '@/services/data/lou'
import { mapState } from 'vuex'
// import Cleave from 'vue-cleave-component'

export default {
  components: {
    // cleave: Cleave,
  },
  computed: {
    ...mapState(['settings', 'user']),
  },
  data() {
    this.lastFetchId = 0
    // this.rekeningSearch = debounce(this.rekeningSearch, 800)
    return {
      editdata: JSON.parse(
        localStorage.getItem('apupptedittemplatekorporasidata'),
      ),
      action: null,
      title: 'Register Nasabah',
      fetching: false,
      loadingMaster: false,
      maskInput: {
        nama: '',
      },
      formData: {
        periode: '',
        kode: '',
        cif: '',
        rekening: '',
        jenis_produk: '',
        nominal: '',
        nama: '',
        nib_sku_skdu: '',
        masa_berlaku: '',
        npwp: '',
        bidang_usaha: '',
        tempat_pendirian: '',
        tgl_pendirian: '',
        bentuk_korporasi: '',
        alamat: '',
        provinsi: '',
        kota: '',
        telepon: '',
        sumberdana: '',
        pendapatan_per_tahun: '',
        tujuan_pembukaan: '',
      },
      masterao: [],
    }
  },
  created() {
    // this.getMaster()
    console.log('this.editdata.id', this.editdata.id)
    if (this.editdata.id !== '') {
      this.action = 'update'
      this.title = 'Update Data'
      this.getEditData()
    } else {
      this.action = 'create'
      this.title = 'Create Data'
    }
    // console.log('this.action', this.action)
  },
  methods: {
    moment,
    changeDateInput(dateMoment, dateString, arrdata) {
      this[arrdata[0]][arrdata[1]] = dateMoment
      // console.log('dateMoment', dateMoment)
      // console.log('dateString', dateString)
      // console.log('arrdata', arrdata)
    },
    getEditData() {
      // var res = await lou.customUrlGet2('apuppt/api/templatekorporasi', { where: { id: this.editdata.id } })
      console.log('this.editdata', this.editdata)
      this.formData = this.$g.clone(this.editdata)
      // this.maskInput.nama = this.editdata.nama
      // this.maskInput.alamat = this.editdata.alamat
      // console.log('this.editdata', this.editdata)
      // this.maskInput.cabang = this.editdata.kode.slice(0, this.editdata.kode.indexOf('.'))
      // this.maskInput.rekode = this.editdata.kode.slice(this.editdata.kode.indexOf('.') + 1, this.editdata.kode.length)
      this.formData.periode = moment(this.editdata.periode, 'YYYY-MM-DD')
      this.formData.tgl_pendirian = moment(this.editdata.tgl_pendirian, 'YYYY-MM-DD')
      // setTimeout(() => {
      //   this.formData.kodya = this.editdata.kodya
      //   this.kotaChange()
      // }, 100)
      // setTimeout(() => {
      //   this.formData.kecamatan = this.editdata.kecamatan
      //   this.kecamatanChange()
      // }, 300)
      // setTimeout(() => {
      //   this.formData.kelurahan = this.editdata.kelurahan
      //   this.kelurahanChange()
    },
    async handleSubmit() {
      // Should format date value before submit.
      var res = false
      var fd = this.$g.clone(this.formData)
      // console.log('fd', fd)
      // fd.rekening = fd.rekening === null || fd.rekening === undefined ? '' : this.formData.rekening
      fd.periode =
        this.formData.periode !== null
          ? this.formData.periode.format('YYYY-MM-DD')
          : null
      // fd.tgllahir =
      //   this.formData.tgllahir !== null
      //     ? this.formData.tgllahir.format('YYYY-MM-DD')
      //     : null
      // fd.anggota = 1
      // fd.kodepos = parseInt(fd.kodepos)
      // fd.kodepos = fd.kodepos.toString()
      if (this.action === 'update') {
        res = await lou.customUrlPut2('apuppt/api/templatekorporasi', fd, true)
      } else {
        delete fd.id
        // delete fd.rekening
        res = await lou.customUrlPost2('apuppt/api/templatekorporasi', fd, true)
      }
      if (res) {
        // lou.shownotif(
        //   'Success',
        //   'Data baru Berhasil ditambahkan dengan nomor Register: ' + res.data,
        //   'success',
        // )
        // Modal.confirm({
        //   title: 'Cetak Validasi?',
        //   content: 'Simpan berhasil!, Cetak Validasi?',
        //   okText: 'Yes',
        //   okType: 'primary',
        //   cancelText: 'No',
        //   onOk: async () => {
        //     var res1 = await lou.ngetDocumentPdf(
        //       'apuppt/api/templatekorporasi/cetak/' + res.data.id,
        //       false,
        //       false,
        //     )
        //     if (res1) {
        //       this.resetForm()
        //       // console.log('res1', res1)
        //       // console.log("Success", res1ponse);
        //       const blob = new Blob([res1], {
        //         type: 'application/pdf',
        //       })
        //       var iframe = this._printIframe
        //       var url = URL.createObjectURL(blob)
        //       if (!this._printIframe) {
        //         iframe = this._printIframe = document.createElement('iframe')
        //         document.body.appendChild(iframe)

        //         iframe.style.display = 'none'
        //         iframe.onload = function () {
        //           setTimeout(function () {
        //             iframe.focus()
        //             iframe.contentWindow.print()
        //           }, 1)
        //         }
        //       }

        //       iframe.src = url
        //       // const link = document.createElement('a')
        //       // link.href = URL.createObjectURL(blob)
        //       // // link.target = '_blank'
        //       // // link.download = filename

        //       // link.download = ''
        //       // link.click()
        //       // // const objectUrl = URL.createObjectURL(blob)
        //       // // console.log('objectUrl', objectUrl)
        //       // // this.amodal.ArrayBuffer = objectUrl
        //       // URL.revokeObjectURL(blob)
        //       setTimeout(() => {
        //         this.componentKey += 1
        //         // this.amodalvisible = true
        //       }, 500)
        //     }
        //   },
        //   onCancel: () => {
        this.resetForm()
        //   },
        // })
      }
    },
    resetForm() {
      localStorage.removeItem('apupptedittemplatekorporasidata')
      this.formData = {
        tgl: moment(),
        kode: '',
        golongantabungan: '',
        ao: '',
        golongankelompok: '',
      }
      this.$router.push('/apuppt/templatekorporasi')
    },
  },
}
</script>

<style scoped>
.ant-card-body {
  padding: 24px !important;
}
.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}
</style>
